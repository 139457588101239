import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withGA } from '../../utils/googleAnalytics';
import PropTypes from 'prop-types';
import Parse from 'parse';
import MetaTags from 'react-meta-tags';

import Button from '../../components/Button';
import InfoBox from '../../components/InfoBox';
import ErrorBox from '../../components/ErrorBox';
import InputField from '../../components/InputField';
import ActionLink from '../../components/ActionLink';
import { eventUserSignIn } from '../../utils/googleAnalytics';
import { getTrackingCode } from '../../utils/trackingCode';
import {
  ERR_INTERNAL_ERROR, ERR_INCORRECT_CREDENTIAL, MSG_SIGNIN_SUCCESS,
  MSG_SIGNIN_ERROR, LOG_IN_TITLE, INFO_BOX_TYPE
} from '../../constants';

const INFUSIONSOFT_USER = 'IA_USER';

class Signin extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    updateUserRole: PropTypes.func.isRequired,
  }

  constructor(props) {
    const params = new URLSearchParams(window.location.search.slice(1));
    let successMsg = null, errorMsg = null;
    if (params.has('error')) {
      errorMsg = MSG_SIGNIN_ERROR[params.get('error')];
    } else if (params.has('success')) {
      successMsg = MSG_SIGNIN_SUCCESS[params.get('success')];
    }

    super(props);
    this.state = {
      successMsg,
      errorMsg,
      email: '',
      password: '',
      loading: false,
      trackingCode: getTrackingCode(),
    };
  }

  componentWillMount() {
    // see note from App.js regarding this localStorage item
    try {
      localStorage.setItem('acquired_update_06-15-19', true);
    } catch (e) {
      return undefined;
    }
  }

  async logIn(e) {
    e.preventDefault();
    const { email, password, trackingCode } = this.state;
    const { history, updateUserRole, adminPage, checkUserPlans } = this.props;
    this.setState({ errorMsg: null, successMsg: null, loading: true });

    try {
      let token;
      if (adminPage) {
        token = await Parse.Cloud.run('adminSignin', { email, password });
      } else {
        token = await Parse.Cloud.run('signIn', { email, password });
      }

      // if user has IA account but doesn't have DIVCASTER account yet, we forward the user information and let them sign-up first
      if(token === INFUSIONSOFT_USER) {
        let selectPlanUrl = `/signup/selectplan?iaEmail=${encodeURIComponent(email)}`;
        if (trackingCode) {
          selectPlanUrl += `&t=${encodeURIComponent(trackingCode)}`;
        }

        history.push(selectPlanUrl);
      }

      await Parse.User.become(token);
      const isAdmin = await Parse.Cloud.run('isAdmin');
      updateUserRole(isAdmin);

      // check user's email verification status
      const currentUser = Parse.User.current();
      
      // check user's plan type, this function is not calling infusionsoft
      await checkUserPlans();

      this.setState({ loading: false });
      if (currentUser.get("emailVerified")) {
        history.push('/');
      } else {
        Parse.User.logOut();
        history.push(`signup/verifyemail?useremail=${encodeURIComponent(currentUser.get('email'))}`);
      }

      // google analytics can run in the background
      const {  attributes: { username } } = currentUser;
      eventUserSignIn(username);

    } catch (error) {      
      if (error.code === Parse.Error.INTERNAL_SERVER_ERROR) {
        this.setState({ errorMsg: ERR_INTERNAL_ERROR, loading: false });
      } else {
        this.setState({ errorMsg: ERR_INCORRECT_CREDENTIAL, loading: false });
      }
    }
  }


  render() {
    const { successMsg, errorMsg, email, password, loading } = this.state;
    const { history } = this.props;

    return (
      <div className="auth-wrapper d-flex align-items-center bg-auth border-top border-top-2 border-primary">
        <div>
          <MetaTags>
            <meta name="og:title" property="og:title" id="og-title" content={LOG_IN_TITLE} />
            <meta name="apple-mobile-web-app-title" id="apple-title" content={LOG_IN_TITLE} />
            <title>{LOG_IN_TITLE}</title>
          </MetaTags>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-5 col-xl-4 my-5">
              <div className="card">
                <div className="card-header">
                  <img src="/img/divcaster-logo.svg" alt="Divcaster by Investors Alley" style={{width: 144, margin: 0, maxWidth: 144}}/>
                </div>
                <div className="card-body">
                  <h2 className="muted-text mt--2 mb-3">
                    Welcome back!
                  </h2>
                  <form onSubmit={(e) => { this.logIn(e); }}>
                    {errorMsg
                      ? <ErrorBox errorMsg={errorMsg} />
                    : <InfoBox infoMsg={successMsg} type={INFO_BOX_TYPE.SUCCESS} />}
                    <div className="form-group">
                      <InputField
                        required
                        type="email"
                        id="loginEmail"
                        label="Email"
                        placeholder="Enter your email"
                        onInputChange={input => this.setState({ email: input })}
                        controlled
                        value={email}
                        />
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col">
                          <label htmlFor="loginPassword">Password</label>
                        </div>
                        <div className="col-auto">
                          <small><ActionLink text="Forgot Password?" clickAction={() => { history.push('resetpassword'); }} tabIndex="-1"/></small>
                        </div>
                      </div>
                      <InputField
                        required
                        type="password"
                        id="loginPassword"
                        placeholder="Enter your password"
                        controlled
                        value={password}
                        onInputChange={input => this.setState({ password: input })}
                        />
                    </div>
                    <Button type="submit" dashClass="btn-block" name="Log In" disabled={loading} loading={loading} />
                  </form>
                </div>
                {/* <div className="card-footer bordered">
                  <button className="btn btn-secondary btn-block" onClick={() => {
                    const { trackingCode } = this.state;

                    let url = '/signup';
                    if (trackingCode) {
                      url += `?t=${encodeURIComponent(trackingCode)}`;
                    }

                    history.push(url);
                  }}>Need an account? Sign up!</button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withGA(withRouter(Signin));
